import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => <Layout fullMenu>
	<article id="main">
		<header>
			<h2>Impressum</h2>
		</header>
		<section className="wrapper style5">
			<div className="inner">

				<section>
					<h2>Impressum</h2>
					<p>Angaben gemäß § 5 TMG</p>
					<p>Andreas Kreisel <br/> 
					   Software Architektur und DevOps - archcraft<br/>
					   Schulstraße 46<br/> 
					   58513 Lüdenscheid</p>

					<h3>Vertreten durch</h3>
					<p>Andreas Kreisel</p>

					<h3>Kontakt</h3>
					<p>Telefon: 02351-6561040<br/>
					   E-Mail: <a href='mailto:info@archcraft.de'>info@archcraft.de</a><br/></p>

					<h3>Umsatzsteuer-ID: </h3>
					<p>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: <i>DE353913859</i></p>

					<h3>Gewerbeanmeldung</h3>
					<p>Die Gewerbeanmeldung nach §34c GewO wurde am 19.05.2022 durch Ordnungsamt Lüdenscheid erteilt.</p>

{/*
					<h3>Angaben zur Berufshaftpflichtversicherung</h3>
					<strong>Name und Sitz des Versicherers:</strong>
					<p>Haftpflichtversicherung<br/>
					   Am Acker<br/>
					   12345 Musterstadt</p>
					<strong>Geltungsraum der Versicherung:</strong>
					<p>Deutschland</p>

					<h3>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h3>
					<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
					Verbraucherschlichtungsstelle teilzunehmen.</p>

					<hr/>
*/}
					<h3>Haftungsausschluss: </h3>
					<h4>Haftung für Inhalte</h4>
					<p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
					Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß
					§ 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
					Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
					fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
					hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
					Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
					einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
					diese Inhalte umgehend entfernen.</p>

					<h4>Haftung für Links</h4>
					<p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
					Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
					Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
					wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
					zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
					ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
					Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

					<h4>Google Analytics</h4>
					<p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (''Google''). Google
					Analytics verwendet sog. ''Cookies'', Textdateien, die auf Ihrem Computer gespeichert werden und die eine
					Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über
					Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA
					übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website
					auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere
					mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese
					Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte
					diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der
					Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung
					Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
					nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website
					erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen
					Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>
 				</section>

			</div>
		</section>
	</article>

</Layout>;

export default IndexPage;
